import * as React from "react"
import logo from "../../assets/img/dataport.svg"
import {WeitereInformationen} from "./WeitereInformationen"

export const Header = () => {
  const [isInfoVisible, setIsInfoVisible] = React.useState(false)

  const toggleInfo = () => {
    setIsInfoVisible(prev => !prev)
  }

  return (
    <header className="header">
      <img src={logo} className="dataport-logo" alt="Dataport" />
      <span className="title">XZUFI-Validator - Beta</span>
      <button className="info-button" onClick={toggleInfo}>
        Weitere Informationen
      </button>
      <div style={{visibility: isInfoVisible ? "visible" : "hidden"}} className="version-info">
        <WeitereInformationen closeInfo={toggleInfo} />
      </div>
    </header>
  )
}
