import React, {ChangeEvent} from "react"
import redaktionssystemliste from "../../assets/data/redaktionssystemliste.json"
import {Redsyskonfig} from "../../models/Redsyskonfig"

interface RedaktionssystemAuswahlProps {
  redsyskonfig: Redsyskonfig
  ladeRedaktionssystem: (ladeCode: string) => void
}

export const RedaktionssystemAuswahl: React.FC<RedaktionssystemAuswahlProps> = ({
  redsyskonfig,
  ladeRedaktionssystem
}) => {
  const {ars, leikaTypen, redaktionssystemId, redsyscode} = redsyskonfig

  const arsStr = ars.join(", ")
  const leikaTypStr = leikaTypen.join(", ")

  //Bei Auswahl eines Redaktionssystems
  const changeRedaktionssystem = (event: ChangeEvent<HTMLSelectElement>) => {
    ladeRedaktionssystem(event.target.value)
  }

  return (
    <div className="region-wrapper">
      <div className="header-region">Redaktionssystem</div>
      <div className="rectangle-region">
        <div className="rectangle-region-inner">
          <div>
            <label className="label-state">
              Wählen Sie ein Redaktionssystem aus
              <select id="lang" className="state" onChange={changeRedaktionssystem} key={redsyscode} value={redsyscode}>
                {redaktionssystemliste.map(redaktionssystem => (
                  <option key={redaktionssystem.redsyscode} value={redaktionssystem.redsyscode}>
                    {redaktionssystem.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
        <div className="rectangle-region-inner">
          <div id="laender-data-table">
            <div id="laender-data-caption">Redaktionssystem spezifische Konfiguration</div>
            <div className="laender-data-body">
              <div className="laender-data-cell-left">ARS</div>
              <div className="laender-data-cell-right"> &nbsp;&nbsp;{arsStr}</div>
            </div>
            <div className="laender-data-body">
              <div className="laender-data-cell-left">Redaktionssystem ID</div>
              <div className="laender-data-cell-right">&nbsp;&nbsp;{redaktionssystemId}</div>
            </div>
            <div className="laender-data-body">
              <div className="laender-data-cell-left">Erlaubte Leika Typen</div>
              <div className="laender-data-cell-right">&nbsp;&nbsp;{leikaTypStr}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
